/* Styles for the hamburger icon */
.hamburgermenu-container {
  position: absolute;
  right: 0;
}
.hamburger {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 35px; /* Width of the hamburger icon */
  height: 30px; /* Height of the hamburger icon */
  right: 0;
  z-index: 10001; /* Ensure it's above other content */
  margin-right: 20px;
}

.bar {
  background-color: rgb(248, 244, 244); /* Color of the hamburger lines */
  height: 3px; /* Height of each line */
  width: 100%; /* Width as per the parent container */
  position: absolute;
  left: 0;
  transition: all 0.3s ease-in-out;
}

.bar:nth-child(1) {
  top: 0px;
}
.bar:nth-child(2) {
  top: 10px;
}
.bar:nth-child(3) {
  top: 20px;
}

/* Styles for the menu */
.menu {
  display: none;
  flex-direction: column;
  background-color: #071013; /* Background of the menu */
  position: fixed;
  top: 10px;
  right: 0;
  width: 250px; /* Width of the menu */
  height: 100%; /* Full-screen menu */
  padding: 8px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Below the hamburger icon */
}

/* Show menu when 'open' class is applied */
.menu.open {
  display: flex;
}

.hamburger.open .bar:nth-child(1) {
  transform: translateY(10px) rotate(45deg);
}

.hamburger.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.open .bar:nth-child(3) {
  transform: translateY(-10px) rotate(-45deg);
}
.instagram-icon {
  border-bottom: 0px !important;
}

.menu a {
  padding: 10px 15px;
  text-decoration: none;
  border-bottom: 1px solid #ddd; /* Separator for items */
  transition: background-color 0.3s ease;
  color: #f17105; /* Text color of the menu items */
}

/* Responsive adjustments */

@media (min-width: 1200px) {
  .hamburger {
    display: none;
  }
}
