.img1 {
  width: 85px;
  max-width: 85px;
  height: 85px;
  align-self: left;
  display: block;
  left: 0;
  margin-left: 3rem;
  margin-right: 19rem;
}

.header {
  user-select: none;
  background-color: #071013; /* Removed quotation marks */
  min-height: 10vh; /* Removed quotation marks and added hyphen */
  display: flex;
  flex-direction: row; /* Removed quotation marks and added hyphen */
  align-items: center; /* Removed quotation marks and added hyphen */
  justify-content: left; /* Removed quotation marks and added hyphen */
  color: white;
  position: relative;
}

.main-menu {
  display: flex;
  flex-direction: row;
  gap: 7%;
}
.breaker {
  font-weight: lighter;
}
.main-menu a {
  color: #f17105; /* Or any color that contrasts well with #071013 */
  font-size: xx-large;
  text-decoration: none; /* Optional: Removes underline from links */
  padding: 8px; /* Adds some space around the links */
}
.main-menu a:hover {
  color: #e7ad7e; /* Or any color that contrasts well with #071013 */
}
/* Media query for smaller screens */
@media (max-width: 1200px) {
  .main-menu {
    display: none;
  }
  .img1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
