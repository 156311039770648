/* AboutUs.css */
.phone-number {
  color: white;
  margin: 0;
  padding: 0;
  font-weight: 500;
  text-decoration: none;
}
.about-us-section {
  background-image: url("../assets/shawarmabg.webp"); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  color: #f17105;
  text-align: center;
  margin-top: auto;
  padding: 150px 50px; /* Increased top and bottom padding */
  position: relative; /* For overlay if needed */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2); /* subtle shadow for depth */
}

/* If you want to add a dark overlay */
.about-us-section::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust color and opacity */
  z-index: 1;
}

.about-us-content {
  position: relative;
  font-weight: 500;

  z-index: 2;
}

.about-us-title {
  font-size: 2em; /* Adjust font-size as needed */
  margin-bottom: 10px;
}

.about-us-address,
.about-us-phone {
  font-size: 1em; /* Adjust font-size as needed */
}

/* Responsive styles and other specific styles should be added as needed. */
@media (max-width: 768px) {
  .about-us-section {
    padding: 80px 20px; /* Adjust padding for smaller screens */
  }
  .about-us-title {
    font-size: 1.5em; /* Adjust font-size for smaller screens */
  }
  .about-us-address,
  .about-us-phone {
    font-size: 0.8em; /* Adjust font-size for smaller screens */
  }
}
