.dev {
  padding: 0;
  margin: 0;
  color: red;
}
.beforeHome {
  background-color: black; /* Black background color */
  height: 90%; /* Full width */
  position: relative;
}

.bigHome {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  padding-top: 60px;
  background-color: rgb(0, 0, 0); /* Adjust to match your site's color scheme */
  color: white;
}
.home {
  /* user-select: none; */
  position: relative; /* Needed for absolute positioning of the child */
  height: 10%; /* Adjust to fit content */
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  text-align: left;
  color: white;
  margin-left: px;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Full height */
  background-image: url("../assets/Shawarma1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.6; /* Apply opacity here */
}

.homeTitle {
  font-size: 3rem; /* Increased font size */
  font-weight: bold;

  margin: 1%;
  padding-top: 10px;
}
.home-image {
  top: 0;
  left: 0;
  right: 300px;
  text-align: right;
  flex: 1;
}
.home-img {
  display: block;
  width: 80%;
  height: auto;
}
.homeText {
  font-size: 2rem; /* Increased font size */
  font-weight: bold;
  margin: 1%;
  padding-top: 1%;
  /* background-color: rgba(0, 0, 0, 0.5); Black with 50% opacity */
}
.call {
  display: flex;
  align-items: center; /* This vertically aligns the flex items */
}

.call_icon {
  margin-right: 8px; /* Adjust space between icon and text */
}

.call_text {
  margin: 1%;
  color: whitesmoke;
  user-select: text;
}

.location {
  display: flex;
  align-items: center; /* This vertically aligns the flex items */
  user-select: text;
}

.call_icon {
  margin-right: 8px; /* Adjust space between icon and text */
}

.call_text {
  margin: 1%;
  color: whitesmoke;
}
/* Rest of your styles */

.number {
  margin: 1%;
  padding-top: 1%;
  color: #f17105;
  text-align: center;
  transition: color 0.3s ease; /* Transition for color change */
}
.subtitle {
  color: white;
}
@media (max-width: 1200px) {
  .homeTitle {
    font-size: 2.5em; /* Smaller font size for smaller screens */
    padding-top: 5%; /* Adjust padding for smaller screens */
  }

  .home {
    align-items: left;
    justify-content: left;
    padding: 50px, 10px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .homeText {
    font-size: 1.5em; /* Smaller font size for smaller screens */
    padding-top: 0.5%; /* Adjust padding for smaller screens */
    margin: 0.5%; /* Adjust margin for smaller screens */
  }
  .subtitle {
    color: white;
  }
  .number {
    font-size: 1.2em; /* Smaller font size for smaller screens */
    margin-bottom: 5px; /* Adjust padding for smaller screens */
  }

  .homeText {
    padding: 15px;
    margin-top: 10px;
  }

  .bigHome {
    flex-direction: row;
    padding: 0;
  }
  .home {
    width: 100%;
    padding: 0;
  }
}
@media (max-width: 768px) {
  .homeTitle {
    font-size: 1.7em; /* Smaller font size for mobile */
    padding-top: 5%; /* Adjust padding for smaller screens */
  }

  .homeText {
    font-size: 0.9em; /* Smaller font size for mobile */
    padding-top: 0.5%; /* Adjust padding for smaller screens */
    margin: 0.5%; /* Adjust margin for smaller screens */
  }
  .subtitle {
    color: white;
  }
  .number {
    font-size: 1.2em; /* Smaller font size for mobile */
    margin-bottom: 5px; /* Adjust padding for smaller screens */
  }

  .homeText {
    padding: 15px;
    margin-top: 10px;
  }
  .home-image {
    display: none;
  }
  .bigHome {
    flex-direction: column;
    padding: 0;
  }
  .home {
    width: 100%;
    padding: 0;
  }
}

.call-button,
.directions-button {
  background-color: #f17105; /* A warm orange color */
  color: white; /* White text */
  border: none; /* No border */
  padding: 7px 26px; /* Top and bottom padding, Left and right padding */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px; /* Adjust the font size as needed */
  margin: 5px 7px; /* Spacing between buttons */
  cursor: pointer; /* Pointer/hand icon */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Box shadow for a subtle lift effect */
  transition-duration: 0.4s; /* Transition for a smooth hover effect */
}

.call-button:hover,
.directions-button:hover {
  background-color: #f16f05e2; /* A slightly darker orange on hover */
  color: rgba(255, 255, 255, 0.903);
  text-decoration: none;
}

/* Optional: Specific styles for each button, if needed */
.call-button {
  /* Specific styles for call button */
}

.directions-button {
  /* Specific styles for directions button */
}
.buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Aligns items to the end of the container */
  align-items: center;
}

.button {
  /* Assign specific class or id to each button if they have different styles */
  margin-left: 10px; /* Adjust space between the buttons */
  /* Add additional styling such as padding, font size, etc. */
}

/* If your buttons have different widths and you want to make them equal: */
.button {
  flex: 1; /* This will make both buttons take equal space */
  text-align: center; /* This centers the text inside the buttons */
  /* Add padding, border, and other properties as needed */
}
