/* About.css */

.big-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.about {
  background: #000000; /* Replace with your desired background color or image */
  padding: 50px; /* Adjust padding to fit your content appropriately */
  color: white; /* Text color for better readability */
  text-align: center; /* Center align the content */
  width: 600px;
  margin: 0, auto;
  display: flex;
  flex-direction: column;
}

.about-title {
  font-size: 2.5rem; /* A larger font size for the title */
  margin-bottom: 20px; /* Space below the title */
  color: white; /* Title color */
  font-weight: bold; /* Making the title bold */
}
.about-title:hover {
  color: #f17105;
}

.about-body {
  font-size: 1rem; /* The font size for the body text */
  line-height: 1.6; /* Line height for better readability */
  color: white; /* Body text color, can be different from the title */
  text-align: center;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .about {
    padding: 25px;
    width: 100%;
  }

  .about-title {
    font-size: 2rem; /* Slightly smaller font size for the title on mobile */
  }

  .about-body {
    font-size: 0.9rem; /* Slightly smaller font size for the body on mobile */
  }
}
