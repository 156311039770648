/* Footer.css */
.footer {
  background-color: #071013; /* Light grey background */
  color: #fff; /* Dark text color */
  text-align: center; /* Center-align the text */
  position: relative;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 100%;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2); /* subtle shadow for depth */
}
p {
  font-size: smaller;
  margin: 0;
  padding: 0;
}
.instagram-icon{
  color: #f17105;
  font-size: 1.5em;
  margin: 0 10px;
  text-decoration: none;
}
