.menuforsale {
  display: flex;
  background-color: black;
  color: white;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.menuforsale h2 {
  color: #f17105;
}

.menu-item {
  margin: 10px 0;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  width: 80%;
}

.menu-item h4 {
  margin: 0;
  padding-bottom: 5px;
}

.menu-item p {
  margin: 0;
}

.colored {
  color: #f17105;
}
.uncolored {
  color: white;
}
