.order h1 {
  color: white;
  margin: 0px;
  padding-bottom: px;
  max-width: 600px;
}
.order {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background-color: black;
}

.door-dash {
  background-color: rgb(251, 18, 12); /* A warm orange color */
  color: white; /* White text */
  border: none; /* No border */
  padding: 7px 26px; /* Top and bottom padding, Left and right padding */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px; /* Adjust the font size as needed */
  margin: 5px 7px; /* Spacing between buttons */
  cursor: pointer; /* Pointer/hand icon */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Box shadow for a subtle lift effect */
  transition-duration: 0.4s; /* Transition for a smooth hover effect */
  margin-top: 30px;
}

.door-dash:hover {
  background-color: grey; /* A slightly darker orange on hover */
  color: rgba(255, 255, 255, 0.903);
  text-decoration: none;
}
.number {
  color: #f17105 !important;
  text-decoration: none;
  white-space: nowrap;
}

.uber-eats {
  background-color: rgb(2, 193, 103);
}

.skip {
  background-color: rgb(255, 128, 1);
  margin-bottom: 50px;
}
